<template>
  <div class="addUser">
    <!-- <el-scrollbar class="elScroll"> -->
      <el-form label-position="right" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="账号" prop="username" v-if="type != 'edit'">
<!--          <el-input v-model="prefixValues" class="prefix" disabled></el-input>-->
          <el-input v-model="ruleForm.username"  @input="trimLR('username')" :disabled="type == 'edit'">
              <template slot="prepend">{{prefixValues}}</template>
          </el-input> <span style="color: red;">固定前缀为{{prefixValues}},不可修改</span>
        </el-form-item>
        <el-form-item label="账号" v-else>
          <el-input v-model.trim="ruleForm.username" :disabled="type == 'edit'"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="realName">
          <el-input v-model="ruleForm.realName" @input="trimLR('realName')"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="phone">
          <el-input v-model="ruleForm.phone" @input="trimLR('phone')" maxlength="16"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model.trim="ruleForm.email" @input="trimLR('email')"></el-input>
        </el-form-item>
        <el-form-item label="帐号状态">
          <el-select v-model="ruleForm.lockFlag" placeholder="选择帐号状态" filterable>
            <el-option label="正常" value="ENABLE"></el-option>
            <el-option label="禁用" value="DISABLE"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色">
          <!-- <el-cascader
          v-model="roleArr"
          :options="roleOptions"
          :props="{ multiple: true, value: 'roleId', label: 'roleName' }"
          @change="roleArrEvent"
          clearable></el-cascader> -->
          <el-checkbox-group v-model="roleArr" @change="roleSelectEvent">
            <el-checkbox :label="item.roleId" v-for="(item, index) in roleOptions" :key="index">{{item.roleName}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="管理区域" prop="address">
          <!-- <el-cascader
          v-model="proArr"
          :options="provinceOptions"
          :props="{ multiple: true, value: 'id', label: 'name' }"
          @change="proArrEvent"
          clearable></el-cascader> -->
          <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
          <el-checkbox-group v-model="ruleForm.regionIds" @change="handleCheckedCitiesChange">
            <el-checkbox v-for="(item, index) in provinceOptions" :label="item.id" :key="index">{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="ruleForm.remarks"></el-input>
        </el-form-item>
      </el-form>
      <div class="saves">
        <el-button @click="goBack">取 消</el-button>
        <el-button class="mainBtn" type="primary" @click="submitForm('ruleForm')">确 认</el-button>
      </div>
    <!-- </el-scrollbar> -->
  </div>
</template>

<script>
import way from '../../api/encapsulation'
export default {
  name: 'addUser',
  data() {
    var validQC = (rule, value, callback) => {
      if (value) {
        if (/[\u4E00-\u9FA5]/g.test(value)) {
          callback(new Error("不能输入汉字"));
        } else {
        // 验证通过
          callback();
        }
        callback();
      }
    };
    var validSpecil = (rule, value, callback) => {
      callback(way.regSpecil(value));
    };
    var validPhone = (rule, value, callback) => {
      callback(way.regPhone(value))
    }
    var validEmail = (rule, value, callback) => {
      callback(way.regEmail(value))
    }
    return {
      prefixValues: 'ptd-',
      ruleForm: {
        email: '',
        lockFlag: 'ENABLE',
        phone: '',
        realName: '',
        regionIds: [],
        remarks: '',
        roleIds: [],
        username: ''
      },
      rules: {
        realName: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { validator: validQC, trigger: ["blur", "change"] },
          { validator: validSpecil, trigger: ["blur", "change"] },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ]
      },
      phone: [
        { validator: validQC, trigger: "change" }
      ],
      email: [
        { validator: validEmail, trigger: "change" }
      ],
      roleOptions: [],
      provinceOptions: [],
      Tid: '',
      allSchoolData: [],
      roleArr: [],
      proArr: [],
      userId: '',
      checkAll: false,
      isIndeterminate: false,
      allIdArr: [],
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        if(newVal.query.hasOwnProperty('type')) {
          this.type = newVal.query.type;
          if(newVal.query.type == 'edit') {
            this.userId = newVal.query.userId;
            this.getStuData(newVal.query.userId);
          }
        }
      }
    }
  },
  created() {
    this.getRoleDownEvent();
    this.getProviceData();
    this.getUsernamePrefixData();
  },
  methods: {
    trimLR(name) {
      this.ruleForm[name] = way.clearSpace(this.ruleForm[name]);
      if(name == 'phone' && this.ruleForm[name]) {
        this.rules.phone = this.phone;
      } else if(name == 'phone') {
        this.$refs['ruleForm'].clearValidate(name);
        delete this.rules.phone;
      }
      if(name == 'email' && this.ruleForm[name]) {
        this.rules.email = this.email;
      } else if(name == 'email') {
        this.$refs['ruleForm'].clearValidate(name);
        delete this.rules.email;
      }
    },
    // 获取角色名称数据
    getRoleDownEvent() {
      this.$request.getRoleDown({}, res=>{
        if(res.code == 0) {
          this.roleOptions = res.data;
        }
      })
    },
    // 获取省份数据
    getProviceData() {
      this.$request.getProviceNew({}, res=>{
        if(res.code == 0) {
          // let arr = [{
          //   id: '',
          //   name: '全部',
          //   children: []
          // }]
          // arr[0].children = res.data;
          // this.provinceOptions = arr;
          this.provinceOptions = res.data;
          // 获取所有省份id
          this.getAllProId();
        }
      })
    },
    getUsernamePrefixData(){
      this.$request.getUsernamePrefix(res => {
        if (res.data){
          this.prefixValues = res.data;
        }
      })
    },
    getAllProId() {
      this.allIdArr = this.provinceOptions.map(item=>item.id)
      // console.log(this.allIdArr.length,this.ruleForm.regionIds.length)
      setTimeout(() => {
        if(this.allIdArr.length == this.ruleForm.regionIds.length) this.checkAll = true;
      }, 1000);
    },
    roleArrEvent(val) {
      // console.log(val)
      this.ruleForm.roleIds = [].concat(...this.roleArr);
    },
    roleSelectEvent(val) {
      this.ruleForm.roleIds = val;
    },
    proArrEvent(val) {
      // console.log(val,this.proArr);
      let newVal = [].concat(...this.proArr);
      let set = new Set(newVal);
      this.ruleForm.regionIds = [...set].filter(i=>i);
      // console.log(this.ruleForm.regionIds)
    },
    handleCheckAllChange(val) {
      this.ruleForm.regionIds = val ? this.allIdArr : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.provinceOptions.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.provinceOptions.length;
    },
    // 获取用户详细数据
    getStuData(userId) {
      this.$request.getUserDetails({userId}, res=>{
        if(res.code == 0) {
          this.ruleForm.realName = res.data.realName;
          this.ruleForm.phone = res.data.phone;
          this.ruleForm.email = res.data.email;
          this.ruleForm.lockFlag = res.data.lockFlag;
          this.ruleForm.remarks = res.data.remarks;
          this.ruleForm.username = res.data.username;
          this.ruleForm.roleIds = res.data.roleIds;
          this.roleArr = res.data.roleIds;
          this.ruleForm.regionIds = res.data.regions;
          // this.proArr = [];
          // res.data.regions.forEach(item => {
          //   this.proArr.push(['', item])
          // })
        }
      });
    },
    // 保存
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(886)
          if(this.type == 'edit') {
            this.editUserInfo();
          } else {
            this.addUserEvent();
          }
        } else {
          return false;
        }
      });
    },
    // 添加用户
    addUserEvent() {
      let formObj = JSON.parse(JSON.stringify(this.ruleForm));
      // if(formObj.phone) {
      //   let phoneStatus = way.regPhone(formObj.phone, 'noEle')
      //   if(!phoneStatus) return;
      // }
      // if(formObj.email) {
      //   let emailStatus = way.regEmail(formObj.email, 'noEle')
      //   if(!emailStatus) return;
      // }
      formObj.username = this.prefixValues + this.ruleForm.username;
      this.$request.addUsers(formObj, res=>{
        if(res.code == 0) {
          this.Success(res.msg);
          this.goBack();
        }
      })
    },
    // 修改用户信息
    editUserInfo() {
      let obj = this.ruleForm;
      obj.userId = this.userId;
      this.$request.editUserInfo(obj).then(res => {
        console.log(res)
        if(res.data.code == 0) {
          this.Success(res.data.msg);
          this.goBack();
        } else {
          this.Warn(res.data.msg)
        }
      }).catch(err => {
        if(err.response.data.code == 1){
          this.Error(err.response.data.msg)
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 返回上一级
    goBack() {
      this.$router.go(-1);
    },
  },
}
</script>

<style lang="less">
  .addUser {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-top: 40px;
    height: calc(~'100% - 68px');
    .elScroll {
      width: 100%;
      height: 100%;
    }
    .el-form {
      width: 580px;
      margin: 0 auto;
      .el-checkbox-group {
        display: flex;
        flex-wrap: wrap;
        .el-checkbox {
          width: 142px;
          display: flex;
          align-items: center;
          .el-checkbox__label {
            display: inline-block;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .prefix {
      width: 60px;
      display: inline-block;
    }
    .users {
      width: 340px;
      display: inline-block;
    }
    .saves {
      text-align: center;
      margin-bottom: 40px;
    }
  }
</style>
